<script setup lang="ts"></script>

<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 5171 1789"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
        <g transform="matrix(3.125,0,0,3.125,0,0)">
            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M242.947,243.393L245.171,240.76C248.485,243.756 251.662,245.254 256.066,245.254C260.333,245.254 263.147,242.985 263.147,239.853L263.147,239.762C263.147,236.811 261.559,235.132 254.886,233.725C247.577,232.136 244.218,229.776 244.218,224.555L244.218,224.464C244.218,219.471 248.621,215.794 254.659,215.794C259.289,215.794 262.603,217.11 265.826,219.698L263.738,222.468C260.787,220.061 257.836,219.016 254.568,219.016C250.437,219.016 247.804,221.286 247.804,224.146L247.804,224.237C247.804,227.233 249.438,228.913 256.429,230.411C263.511,231.954 266.779,234.542 266.779,239.399L266.779,239.49C266.779,244.937 262.24,248.478 255.93,248.478C250.891,248.478 246.76,246.798 242.947,243.393Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M301.373,232.227L301.373,232.136C301.373,224.919 296.107,219.016 288.844,219.016C281.581,219.016 276.406,224.827 276.406,232.046L276.406,232.136C276.406,239.354 281.672,245.254 288.935,245.254C296.198,245.254 301.373,239.444 301.373,232.227ZM272.683,232.227L272.683,232.136C272.683,223.375 279.265,215.703 288.935,215.703C298.604,215.703 305.096,223.284 305.096,232.046L305.096,232.136C305.096,240.897 298.513,248.569 288.844,248.569C279.175,248.569 272.683,240.988 272.683,232.227Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M312.68,216.248L316.265,216.248L316.265,244.709L334.151,244.709L334.151,248.024L312.68,248.024L312.68,216.248Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-575.925)">
                <path
                    d="M339.374,234.223L339.374,215.748L342.96,215.748L342.96,233.997C342.96,240.851 346.592,244.709 352.675,244.709C358.531,244.709 362.253,241.168 362.253,234.223L362.253,215.748L365.84,215.748L365.84,233.951C365.84,243.211 360.528,248.024 352.584,248.024C344.73,248.024 339.374,243.211 339.374,234.223Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M382.729,219.561L372.061,219.561L372.061,216.248L397.028,216.248L397.028,219.561L386.36,219.561L386.36,248.024L382.729,248.024L382.729,219.561Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <rect
                    x="404.067"
                    y="216.248"
                    width="3.587"
                    height="31.776"
                    style="fill: rgb(146, 148, 151)"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M444.242,232.227L444.242,232.136C444.242,224.919 438.976,219.016 431.713,219.016C424.45,219.016 419.275,224.827 419.275,232.046L419.275,232.136C419.275,239.354 424.541,245.254 431.804,245.254C439.067,245.254 444.242,239.444 444.242,232.227ZM415.553,232.227L415.553,232.136C415.553,223.375 422.135,215.703 431.804,215.703C441.473,215.703 447.965,223.284 447.965,232.046L447.965,232.136C447.965,240.897 441.383,248.569 431.713,248.569C422.044,248.569 415.553,240.988 415.553,232.227Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M455.549,216.248L458.908,216.248L478.927,241.713L478.927,216.248L482.423,216.248L482.423,248.024L479.562,248.024L459.044,221.968L459.044,248.024L455.549,248.024L455.549,216.248Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-578.008)">
                <path
                    d="M489.326,243.393L491.55,240.76C494.864,243.756 498.042,245.254 502.445,245.254C506.712,245.254 509.526,242.985 509.526,239.853L509.526,239.762C509.526,236.811 507.937,235.132 501.264,233.725C493.956,232.136 490.597,229.776 490.597,224.555L490.597,224.464C490.597,219.471 495,215.794 501.038,215.794C505.668,215.794 508.981,217.11 512.205,219.698L510.117,222.468C507.166,220.061 504.216,219.016 500.947,219.016C496.816,219.016 494.182,221.286 494.182,224.146L494.182,224.237C494.182,227.233 495.817,228.913 502.808,230.411C509.889,231.954 513.158,234.542 513.158,239.399L513.158,239.49C513.158,244.937 508.619,248.478 502.308,248.478C497.269,248.478 493.139,246.798 489.326,243.393Z"
                    style="fill: rgb(146, 148, 151); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1004.19)">
                <path
                    d="M242.558,283.384L242.558,283.277C242.558,272.448 251.052,263.955 262.572,263.955C269.101,263.955 273.72,265.972 277.648,269.369L271.596,276.642C268.942,274.412 266.288,273.138 262.625,273.138C257.263,273.138 253.122,277.65 253.122,283.331L253.122,283.437C253.122,289.436 257.316,293.735 263.209,293.735C265.704,293.735 267.615,293.205 269.154,292.196L269.154,287.684L261.828,287.684L261.828,280.146L278.922,280.146L278.922,296.867C274.993,300.106 269.526,302.601 262.784,302.601C251.211,302.601 242.558,294.638 242.558,283.384Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1004.19)">
                <path
                    d="M284.653,264.698L314.54,264.698L314.54,273.457L294.846,273.457L294.846,279.084L312.682,279.084L312.682,287.206L294.846,287.206L294.846,293.098L314.806,293.098L314.806,301.858L284.653,301.858L284.653,264.698Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1005.3)">
                <path
                    d="M341.931,287.472L337.79,276.908L333.597,287.472L341.931,287.472ZM332.906,264.698L342.833,264.698L358.652,302.124L347.61,302.124L344.903,295.488L330.57,295.488L327.916,302.124L317.086,302.124L332.906,264.698Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1004.19)">
                <path
                    d="M379.353,282.534C382.857,282.534 384.874,280.836 384.874,278.129L384.874,278.022C384.874,275.103 382.751,273.616 379.3,273.616L372.558,273.616L372.558,282.534L379.353,282.534ZM362.26,264.698L379.831,264.698C385.512,264.698 389.439,266.184 391.934,268.732C394.111,270.856 395.225,273.722 395.225,277.385L395.225,277.491C395.225,283.171 392.2,286.941 387.582,288.905L396.447,301.858L384.556,301.858L377.071,290.604L372.558,290.604L372.558,301.858L362.26,301.858L362.26,264.698Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1004.19)">
                <path
                    d="M418.422,293.576C421.554,293.576 423.359,292.355 423.359,290.179L423.359,290.072C423.359,288.055 421.766,286.728 418.315,286.728L410.459,286.728L410.459,293.576L418.422,293.576ZM416.935,279.508C420.067,279.508 421.819,278.447 421.819,276.271L421.819,276.164C421.819,274.147 420.226,272.979 417.147,272.979L410.459,272.979L410.459,279.508L416.935,279.508ZM400.426,264.698L419.483,264.698C424.155,264.698 427.446,265.866 429.623,268.042C431.109,269.528 432.012,271.493 432.012,274.041L432.012,274.147C432.012,278.447 429.57,280.995 426.278,282.428C430.791,283.915 433.657,286.463 433.657,291.506L433.657,291.612C433.657,297.983 428.455,301.858 419.59,301.858L400.426,301.858L400.426,264.698Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1004.19)">
                <path
                    d="M466.621,283.384L466.621,283.277C466.621,277.916 462.746,273.245 457.066,273.245C451.439,273.245 447.67,277.81 447.67,283.171L447.67,283.277C447.67,288.639 451.545,293.311 457.173,293.311C462.852,293.311 466.621,288.745 466.621,283.384ZM437.106,283.384L437.106,283.277C437.106,272.608 445.706,263.955 457.173,263.955C468.639,263.955 477.133,272.502 477.133,283.171L477.133,283.277C477.133,293.948 468.532,302.601 457.066,302.601C445.599,302.601 437.106,294.054 437.106,283.384Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-1004.19)">
                <path
                    d="M489.711,282.959L477.502,264.698L489.34,264.698L495.922,275.155L502.557,264.698L514.131,264.698L501.921,282.853L514.661,301.858L502.823,301.858L495.71,290.656L488.543,301.858L476.971,301.858L489.711,282.959Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-787.263)">
                <rect
                    x="182.563"
                    y="229.485"
                    width="43.91"
                    height="55.523"
                    style="fill: rgb(0, 62, 82)"
                />
            </g>

            <g transform="matrix(3.32776,-2.50742,-2.50742,-3.32776,327.725,1487.02)">
                <path
                    d="M94.15,255.654L162.735,255.654L195.696,299.4L127.111,299.399L94.15,255.654Z"
                    style="fill: rgb(0, 62, 82); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(-3.32776,-2.50742,-2.50742,3.32776,1188.73,-50.6036)">
                <path
                    d="M94.15,258.108L162.735,258.108L195.696,214.362L127.11,214.364L94.15,258.108Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(3.32769,-2.50751,-2.50751,-3.32769,269.661,1657.47)">
                <path
                    d="M148.314,214.363L216.896,214.363L249.859,258.107L181.275,258.106L148.314,214.363Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>
        </g>
    </svg>
</template>
