<script setup lang="ts"></script>

<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 1419 1789"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
    >
        <g transform="matrix(3.125,0,0,3.125,0,0)">
            <g transform="matrix(4.16667,0,0,4.16667,-489.729,-787.263)">
                <rect
                    x="182.563"
                    y="229.485"
                    width="43.91"
                    height="55.523"
                    style="fill: rgb(0, 62, 82)"
                />
            </g>

            <g transform="matrix(3.32776,-2.50742,-2.50742,-3.32776,327.725,1487.02)">
                <path
                    d="M94.15,255.654L162.735,255.654L195.696,299.4L127.111,299.399L94.15,255.654Z"
                    style="fill: rgb(0, 62, 82); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(-3.32776,-2.50742,-2.50742,3.32776,1188.73,-50.6036)">
                <path
                    d="M94.15,258.108L162.735,258.108L195.696,214.362L127.11,214.364L94.15,258.108Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>

            <g transform="matrix(3.32769,-2.50751,-2.50751,-3.32769,269.661,1657.47)">
                <path
                    d="M148.314,214.363L216.896,214.363L249.859,258.107L181.275,258.106L148.314,214.363Z"
                    style="fill: rgb(23, 154, 214); fill-rule: nonzero"
                />
            </g>
        </g>
    </svg>
</template>
